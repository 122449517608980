import React from "react"
import Card from "../components/Blog/card"
import Layout from "../components/layout"
import Heading from "../components/Layout/heading"
import MaxWidth from "../components/Layout/maxWidth"
import SEO from "../components/seo"
import { css } from "linaria"
import { graphql } from "gatsby"

export default function News({ data }) {
  return (
    <Layout>
      <SEO title="About us" />
      <Heading>
        <h1>News</h1>
      </Heading>

      <MaxWidth>
        <main className={posts}>
          {data.allMdx.nodes.map(post => {
            return <Card post={post} />
          })}
        </main>
      </MaxWidth>
    </Layout>
  )
}

const posts = css`
  display: flex;
  margin: 0 -1em;
  > * {
    flex: 0 1 calc(50% - 2em);
    margin: 0 1em;
  }
  @media (max-width: 800px) {
    flex-wrap: wrap;
    > * {
      flex: 1 1 auto;
    }
  }
`

export const query = graphql`
  query BlogPosts {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMMM, YYYY")
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
